import useSWR from 'swr';
import { ExplodedAPI } from '../ExplodedViewsPage';
import { useExplodedViewData } from '../ExplodedViewProvider';
import { styled } from 'stitches.config';
import KexLink from 'Kex/KexLink';
import ExplodedViewPart from 'ExplodedViews/ExplodedViewPart.interface';
import ExplodedViewAssemblyInfo from 'ExplodedViews/ExplodedViewAssemblyInfo.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import Button from 'Atoms/Buttons/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SetQuantity } from 'Shared/Cart/Cart';
import FitmentRibbon from '../FitmentRibbon';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { getUrlParameter } from 'Shared/Common/Helpers';
import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import { ArrowIcon, CartIcon } from 'Atoms/Icons';
// PrismaZoom doesn't have typescript support
// @ts-ignore
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import QuantitySelector from 'Atoms/Quantity/QuantitySelector';

const MOBILE_IMAGE_SIZE = 1;
const DESKTOP_IMAGE_SIZE = 3;

const StepThree = () => {
  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  const { languageRoute, selectedModel, selectedAssembly } =
    useExplodedViewData();

  const { explodedViews } = useTranslationData();

  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();

  const { data: assemblies, isValidating } = useSWR<
    ExplodedViewAssemblyInfo | undefined
  >(
    `/api/ExplodedViews/AssemblyInfo?language=${languageRoute}&parentid=${
      selectedAssembly.parentId
    }&assemblyId=${selectedAssembly.assemblyId}&zoomLevel=${
      isMobile ? MOBILE_IMAGE_SIZE : DESKTOP_IMAGE_SIZE
    }`,
    ExplodedAPI,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch({
      type: isValidating ? 'add' : 'remove',
      from: 'Exploded Views Step Three',
    });
  }, [isValidating, kexLoadingCircleDispatch]);

  const partsRef = useRef(new Map<string, HTMLDivElement>());
  const addToRefs: (
    el: HTMLDivElement,
    p: ExplodedViewPart,
    i: number
  ) => void = (el, p, i) => {
    if (el && assemblies?.parts[i - 1]?.tag !== p.tag) {
      partsRef.current.set(p.tag, el);
    }
  };

  const fullscreenImage = useMemo(() => {
    if (isMobile) {
      return assemblies?.imageUrl.replace(
        `ariz=${MOBILE_IMAGE_SIZE}`,
        'ariz=3'
      );
    } else {
      return assemblies?.imageUrl.replace(
        `ariz=${DESKTOP_IMAGE_SIZE}`,
        'ariz=5'
      );
    }
  }, [assemblies?.imageUrl, isMobile]);

  if (!assemblies) return <></>;

  return (
    <>
      <FitmentRibbon
        fitmentLabel={explodedViews.showingExplodedViewsFor}
        bikeName={selectedModel.name || getUrlParameter('selectedModelName')}
      />
      <ContentContainer>
        <Heading>
          <H2>{assemblies.name}</H2>
        </Heading>
        <ThumbnailWrapper>
          <Thumbnail href={fullscreenImage} fontFamily="primary500">
            <span>{explodedViews.openImageInFullScreen}</span>
            <ArrowIcon
              size={'m'}
              color={'primary'}
              rotateLeft
              css={{ display: 'inline-block', verticalAlign: 'top', ml: 2 }}
            />
          </Thumbnail>
        </ThumbnailWrapper>

        <HotspotContainer>
          <HotspotWrapper>
            {assemblies.hotSpots.map((hsp, i) => {
              return (
                <HotspotTag
                  key={`${hsp.tag}${i}`}
                  onClick={() => {
                    const matchRef = partsRef.current.get(hsp.tag);

                    matchRef?.focus({ preventScroll: true });

                    matchRef?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                  }}
                  css={{
                    left: hsp.topLeft.x - 8,
                    top: hsp.topLeft.y - 8,
                  }}
                >
                  {hsp.tag}
                </HotspotTag>
              );
            })}
            <HotspotImage src={assemblies.imageUrl} />
          </HotspotWrapper>
        </HotspotContainer>

        <PartsList>
          {assemblies.parts.map((p, i) => {
            return (
              <PartItem
                tagRef={(el: HTMLDivElement) => addToRefs(el, p, i)}
                key={p.sku + i}
                item={p}
                languageRoute={languageRoute}
                isMobile={isMobile}
              />
            );
          })}
        </PartsList>
      </ContentContainer>
    </>
  );
};

type PartItemType = {
  item: ExplodedViewPart;
  languageRoute: string;
  isMobile: boolean;
  tagRef: (el: HTMLDivElement) => void;
};

const PartItem = ({ item, languageRoute, isMobile, tagRef }: PartItemType) => {
  const { description, tag, url, sku, price, stock } = item;

  const [quantity, setQuantity] = useState<number>(1);
  const [isShowStepper, setShowStepper] = useState<boolean>(false);

  return (
    <Part tabIndex={0} ref={tagRef}>
      <StyledKexLink
        href={url}
        key={sku}
        css={{ cursor: url ? 'pointer' : 'default' }}
        underlined={false}
      >
        <ProductHeading>
          <ProductName>
            {tag}. <Name hasNoLink={!url}>{description.toLowerCase()}</Name>
          </ProductName>

          <ProductCode> {sku}</ProductCode>
        </ProductHeading>
      </StyledKexLink>
      <ProductCTA>
        {price.price !== 0 && (
          <>
            <ProductPriceWrapper>
              <Indicator inStock={stock.stockAvailableAtVendor} />
              <ProductPrice> {price.priceRoundedAsString}</ProductPrice>
            </ProductPriceWrapper>
          </>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isShowStepper || !isMobile ? (
            <QuantityWrapper>
              <QuantitySelector
                size={isMobile ? 's' : 'l'}
                maxQuantity={stock.stockQuantity}
                minQuantity={1}
                code={sku}
                handleIncrement={() => setQuantity(quantity + 1)}
                handleDecrement={() => setQuantity(quantity - 1)}
                currentQuantity={quantity}
                disabled={!stock.stockAvailableAtVendor}
              />
            </QuantityWrapper>
          ) : (
            <CurrentQuantity
              onClick={() => {
                if (!stock.stockAvailableAtVendor) return;

                setShowStepper(true);
              }}
            >
              {quantity}
            </CurrentQuantity>
          )}

          <Button
            onClick={() => SetQuantity(sku, quantity, languageRoute)}
            type="primary"
            css={ProductButton}
            disabled={!stock.stockAvailableAtVendor}
            size={isMobile ? 's' : 'l'}
          >
            {stock.stockAvailableAtVendor ? (
              <CartIcon size="s" color="secondaryDark" />
            ) : (
              <CartIcon
                size="s"
                color="secondaryDark"
                css={{ cursor: 'not-allowed', opacity: 0.5 }}
              />
            )}
          </Button>
        </div>
      </ProductCTA>
    </Part>
  );
};
export default StepThree;

const Heading = styled('div', {
  my: 8,
  '@mediaMinLarge': {
    mt: 16,
    mb: 12,
  },
});

const Thumbnail = styled(KexLink, {
  display: 'flex',
  textTransform: 'uppercase',
  width: '100%',
  maxWidth: 500,
});

const HotspotContainer = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  mb: 16,
});

const HotspotWrapper = styled('div', {
  position: 'relative',
  width: 'fit-content',
  margin: '0 auto',
});

const HotspotImage = styled('img', {
  objectFit: 'cover',
  maxWidth: 'unset',
});

const HotspotTag = styled('button', {
  backgroundColor: '$primary6',
  borderRadius: '50%',
  width: 24,
  height: 24,
  fontSize: '12px',
  position: 'absolute',
  '@mediaMinLarge': {
    width: 32,
    height: 32,
  },
});

const ThumbnailWrapper = styled('div', {
  mb: 4,
});

const PartsList = styled('div', {
  mb: 24,
});

const Part = styled('div', {
  position: 'relative',
  backgroundColor: '$primary3',
  p: 4,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  rowGap: 24,
  '@mediaMinLarge': {
    px: 8,
    py: 7,
  },
  mb: 2,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  transition: 'border 0.30s ease-in-out',
  '&:focus': {
    borderColor: '$JE68GreenPrimary',
  },
});

const StyledKexLink = styled(KexLink, {
  mb: 0,
  fontSize: '20px',
  fontFamily: '$fontSecondary400',
  wordSpacing: 0,
  lineHeight: '$lh120',
  ls: '$ls0',
  zIndex: '$ProductCardMain',
});

const ProductName = styled('p', {
  fs: 7,
  lineHeight: '$lh12',
  fontFamily: '$fontSecondary600',
  textTransform: 'capitalize',
  '@mediaMinLarge': {
    fs: 10,
  },
});

const Name = styled('span', {
  variants: {
    hasNoLink: {
      true: {
        textDecoration: 'none',
      },
      false: {
        textDecoration: 'solid underline',
      },
    },
  },
});

const ProductButton = {
  display: 'inline-block',
  flex: '1 1 100%',
  '@mediaMinLarge': {},
  maxWidth: 233,
};

const ProductPriceWrapper = styled('div', {
  mr: 2,
  display: 'flex',
  alignItems: 'center',
  '@mediaMinLarge': {
    mr: 16,
  },
});

const ProductPrice = styled('span', {
  fs: 7,
  lineHeight: '$lh12',
  fontFamily: '$fontSecondary600',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  '@mediaMinLarge': {
    fs: 10,
  },
});

const ProductCode = styled('span', {
  fs: 6,
  lineHeight: '$lh15',
  fontFamily: '$fontSecondary400',
  '@mediaMinLarge': {
    fs: 8,
  },
});

const CurrentQuantity = styled('button', {
  backgroundColor: 'rgba(255, 255, 255, 0.0263)',
  px: 6,
  py: 3,
  color: '$secondary2',
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  minW: 12,
});

export const Indicator = styled('span', {
  display: 'inline-block',
  borderRadius: '50%',
  background: '$JE68GreenPrimary',
  w: 2,
  h: 2,
  mr: 2,
  verticalAlign: 'middle',
  variants: {
    inStock: {
      true: {
        backgroundColor: '$JE68GreenPrimary',
      },
      false: {
        backgroundColor: 'red',
      },
    },
  },
  '@mediaMinLarge': {
    mr: 4,
  },
});

const ProductHeading = styled('div', {
  flex: '1 0 100%',
  '@mediaMinLarge': {
    flex: '1 0 50%',
  },
});

const ProductCTA = styled('div', {
  flex: '1 0 100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@mediaMinLarge': { flex: '1 0 50%', justifyContent: 'flex-end' },
});

const QuantityWrapper = styled('div', {
  mr: 2,
  '@mediaMinLarge': {
    mr: 4,
    flex: '1 0 50%',
  },
  justifyContent: 'flex-end',
  alignItems: 'center',
});
