import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import { styled } from 'stitches.config';

type PropType = {
  bikeName: string;
  fitmentLabel: string;
};

const FitmentRibbon = ({ bikeName, fitmentLabel }: PropType) => {
  return (
    <FitmentContainer>
      <ContentContainer>
        <FitmentWrapper>
          <div>
            {fitmentLabel}: <FitmentBike>KTM {bikeName}</FitmentBike>
          </div>
        </FitmentWrapper>
      </ContentContainer>
    </FitmentContainer>
  );
};

const FitmentContainer = styled('div', {
  backgroundColor: '$primaryLight2',
  position: 'sticky',
  fs: 6,
  lineHeight: '$lh133',
  color: '$primary6',
  py: 2,
});

const FitmentWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const FitmentBike = styled('span', {});

export default FitmentRibbon;
