import React, { useState, ReactNode, useEffect } from 'react';
import { getUrlParameter } from 'Shared/Common/Helpers';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

type SelectedModel = {
  id: number;
  name: string;
};

type SelectedYear = {
  id: number;
  name: string;
  shouldFetch: boolean;
};

type SelectedAssembly = {
  parentId: number;
  assemblyId: number;
};

export const ExplodedViewContext = React.createContext({
  step: 1,
  setStep: (step: number) => {},
  languageRoute: '',
  selectedModel: {
    id: 0,
    name: '',
  },
  setSelectedModel: (modelObj: SelectedModel) => {},
  selectedYear: {
    id: 0,
    name: '',
    shouldFetch: false,
  },
  setSelectedYear: (yearObj: SelectedYear) => {},
  selectedAssembly: {
    parentId: 0,
    assemblyId: 0,
  },
  setSelectedAssembly: (assembly: SelectedAssembly) => {},
  setWindowHref: (href: string) => {},
});

export const useExplodedViewData = () => {
  return React.useContext(ExplodedViewContext);
};

type Props = {
  children: ReactNode;
};

export const ExplodedViewProvider = ({ children }: Props) => {
  const [step, setStep] = useState<number>(parseInt(getUrlParameter('step')));

  const [selectedYear, setSelectedYear] = useState<SelectedYear>({
    id: parseInt(getUrlParameter('selectedYear')),
    name: getUrlParameter('selectedYearName'),
    shouldFetch: false,
  });

  const [selectedModel, setSelectedModel] = useState<SelectedModel>({
    id: parseInt(getUrlParameter('selectedModel')),
    name: getUrlParameter('selectedModelName'),
  });

  const [selectedAssembly, setSelectedAssembly] = useState<SelectedAssembly>({
    parentId: parseInt(getUrlParameter('selectedAssemblyParentId')),
    assemblyId: parseInt(getUrlParameter('selectedAssemblyId')),
  });

  const { languageRoute } = useAppSettingsData();

  // window href state is for browser back button to work correctly
  const [windowHref, setWindowHref] = useState<string>('');

  useEffect(() => {
    const listenToPopstate = () => {
      setWindowHref(window.location.href);
    };

    window.addEventListener('popstate', listenToPopstate);
    return () => {
      window.removeEventListener('popstate', listenToPopstate);
    };
  }, []);

  useEffect(() => {
    setStep(parseInt(getUrlParameter('step')) || 1);
  }, [windowHref]);

  return (
    <ExplodedViewContext.Provider
      value={{
        step,
        setStep,
        languageRoute,
        selectedModel,
        setSelectedModel,
        selectedYear,
        setSelectedYear,
        selectedAssembly,
        setSelectedAssembly,
        setWindowHref,
      }}
    >
      {children}
    </ExplodedViewContext.Provider>
  );
};
