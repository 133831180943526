import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ExplodedViewsPageModel from 'Models/Pages/ExplodedViews/ExplodedViewsPageModel.interface';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import { styled } from 'stitches.config';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import KTM from '../../../Shared/Images/brands/KTM.svg';
import Button from 'Atoms/Buttons/Button';
import ExplodedViewNodeChild from 'ExplodedViews/ExplodedViewNodeChild.interface';
import useSWR from 'swr';
import SingleSelectDropDown from 'Molecules/DropDowns/SingleSelectDropDown';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { useMemo } from 'react';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { ExplodedAPI } from '../ExplodedViewsPage';
import { useExplodedViewData } from '../ExplodedViewProvider';
import { setUrlParametersWithHistory } from 'Shared/Common/Helpers';

function StepOne() {
  const { heroImage, inEditMode, heroHeader } =
    useCurrentPage<ExplodedViewsPageModel>();

  const {
    motorcycleModelFinderLabels: { year, model },
    commonLabels: { search },
    explodedViews: {
      findForExplodedViewForMotorcycle,
      findForExplodedViewDescription,
    },
  } = useTranslationData();

  const {
    step,
    setStep,
    selectedModel,
    setSelectedModel,
    selectedYear,
    setSelectedYear,
    setWindowHref,
  } = useExplodedViewData();

  const { languageRoute } = useAppSettingsData();

  const { data: years } = useSWR<ExplodedViewNodeChild[] | undefined>(
    '/api/ExplodedViews/Years',
    ExplodedAPI,
    {
      revalidateOnFocus: false,
    }
  );

  const { data: models } = useSWR<ExplodedViewNodeChild[] | undefined>(
    selectedYear.shouldFetch
      ? `/api/ExplodedViews/Models?language=${languageRoute}&parentid=${selectedYear.id}`
      : null,
    ExplodedAPI,
    {
      revalidateOnFocus: false,
    }
  );

  const memoizedYears = useMemo(
    () =>
      years?.map((year: ExplodedViewNodeChild) => {
        return {
          key: year.id,
          value: year.name,
        };
      }),
    [years]
  );

  const memoizedModels = useMemo(
    () =>
      models?.map((year: ExplodedViewNodeChild) => {
        return {
          key: year.id,
          value: year.name,
        };
      }),
    [models]
  );

  return (
    <>
      {heroImage && (
        <SmallHeroBlock
          header={heroHeader}
          inEditMode={inEditMode}
          image={heroImage}
          overlay={false}
        />
      )}
      <Container>
        <H3>{findForExplodedViewForMotorcycle}</H3>
        <Paragraph>{findForExplodedViewDescription}</Paragraph>
        <BrandItem>
          <BrandImage src={KTM} alt="" />
        </BrandItem>
        <Select>
          <SingleSelectDropDown
            handleSelect={(key, value) => {
              setSelectedYear({
                id: key,
                name: value,
                shouldFetch: true,
              });
            }}
            values={memoizedYears}
            currentValue={selectedYear.name || year}
            fullWidth
          />
          <SingleSelectDropDown
            handleSelect={(key, value) => {
              setSelectedModel({ id: key, name: value });
            }}
            values={memoizedModels}
            currentValue={selectedModel.name || model}
            fullWidth
          />
        </Select>
        <Button
          type="primary"
          fullWidth
          onClick={() => {
            let params: [string, string][] = [];

            params.push(['step', (step + 1).toString()]);
            params.push(['selectedModel', selectedModel.id.toString()]);
            params.push(['selectedModelName', selectedModel.name]);
            params.push(['selectedYear', selectedYear.id.toString()]);
            params.push(['selectedYearName', selectedYear.name.toString()]);

            setUrlParametersWithHistory(params);

            setWindowHref(window.location.href);

            setStep(step + 1);
          }}
          disabled={isNaN(selectedModel.id)}
        >
          {search}
        </Button>
      </Container>
    </>
  );
}

const Container = styled('div', {
  maxWidth: 640,
  backgroundColor: '$primary4',
  margin: '0 auto',
  p: 8,
  my: 16,
});

const BrandItem = styled('div', {
  backgroundColor: '$primary2',
  border: '1px solid $JE68GreenPrimary',
  width: 152,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mt: 16,
  mb: 12,
});

const BrandImage = styled('img', {
  objectFit: 'contain',
});

const Select = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  mb: 8,
});

export default StepOne;
