import useSWR from 'swr';
import { ExplodedAPI } from '../ExplodedViewsPage';
import { useExplodedViewData } from '../ExplodedViewProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ExplodedViewsPageModel from 'Models/Pages/ExplodedViews/ExplodedViewsPageModel.interface';
import SmallHeroBlock from 'Organisms/Blocks/SmallHeroBlock';
import { styled } from 'stitches.config';
import { timings } from 'Theme/Settings/animation';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import FitmentRibbon from '../FitmentRibbon';
import ExplodedViewNodeChild from 'ExplodedViews/ExplodedViewNodeChild.interface';
import ExplodedViewsTranslations from 'Models/Translations/ExplodedViewsTranslations.interface';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { canUseDOM, setUrlParametersWithHistory } from 'Shared/Common/Helpers';
import Attributes from 'Molecules/Attributes/Attributes';
import { useEffect, useState } from 'react';
import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import { ENUM_KTM } from 'Shared/Constants/brand';

const StepTwo = () => {
  const { heroImage, inEditMode, heroHeader } =
    useCurrentPage<ExplodedViewsPageModel>();

  const { languageRoute, selectedModel, selectedYear } = useExplodedViewData();

  const { explodedViews } = useTranslationData();

  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();

  const { data: assemblies, isValidating } = useSWR<
    ExplodedViewNodeChild[] | undefined
  >(
    `/api/ExplodedViews/Assemblies?language=${languageRoute}&parentid=${selectedModel.id}`,
    ExplodedAPI,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch({
      type: isValidating ? 'add' : 'remove',
      from: 'Exploded Views Step Two',
    });
  }, [isValidating, kexLoadingCircleDispatch]);

  return (
    <>
      <FitmentRibbon
        fitmentLabel={explodedViews.showingExplodedViewsFor}
        bikeName={`${selectedModel.name} ${selectedYear.name}`}
      />
      {heroImage && (
        <SmallHeroBlock
          header={heroHeader}
          inEditMode={inEditMode}
          image={heroImage}
          overlay={false}
        />
      )}
      <ContentContainer>
        <AssemblyList>
          {assemblies?.map((a) => {
            return (
              <AssemblyItem item={a} key={a.id} translations={explodedViews} />
            );
          })}
        </AssemblyList>
      </ContentContainer>
    </>
  );
};

type ItemType = {
  item: ExplodedViewNodeChild;
  translations: ExplodedViewsTranslations;
};

const AssemblyItem = ({ item, translations }: ItemType) => {
  const { id, name, imageUrl, parentId } = item;

  const { setSelectedAssembly, setStep, step, setWindowHref } =
    useExplodedViewData();

  const [isCardHovered, setIsCardHovered] = useState<boolean>(false);

  function handleClick() {
    if (!canUseDOM()) return;

    setSelectedAssembly({
      parentId: parentId,
      assemblyId: id,
    });

    setStep(step + 1);

    let params: [string, string][] = [];

    params.push(['step', (step + 1).toString()]);
    params.push(['selectedAssemblyId', id.toString()]);
    params.push(['selectedAssemblyParentId', parentId.toString()]);

    setUrlParametersWithHistory(params);

    setWindowHref(window.location.href);
  }

  return (
    <Card
      onMouseOver={() => setIsCardHovered(true)}
      onMouseOut={() => setIsCardHovered(false)}
    >
      <ProductImageWrapper>
        <Link onClick={handleClick} key={id}>
          <ProductImage src={imageUrl} alt={name} />
        </Link>
      </ProductImageWrapper>

      <Link onClick={handleClick} key={id}>
        <Attributes
          attributeList={[
            { value: translations.explodedView },
            { value: ENUM_KTM },
          ]}
          css={{ mt: 2 }}
          marginBottom={0}
        />
        <ProductHeading isCardHovered={isCardHovered}>
          {name.toLowerCase()}
        </ProductHeading>
      </Link>
    </Card>
  );
};

const AssemblyList = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
  maxWidth: 'auto',
  '@media(min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '32px',
  },
  '@mediaMinLarge': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '32px',
  },
  my: 16,
});

const Link = styled('div', {
  display: 'block',
  mb: 0,
  fontSize: '20px',
  fontFamily: '$fontSecondary400',
  wordSpacing: 0,
  lineHeight: '$lh120',
  ls: '$ls0',
  gridColumn: '1',
  gridRow: '1',
  zIndex: '$ProductCardMain',
});

const Card = styled('div', {
  cursor: 'pointer',
  position: 'relative',
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'grid',
  gridTemplate: '1fr / 1fr',
});

const ProductImage = styled('img', {
  left: 0,
  height: '100%',
  transition: 'transform 0.2s ease',
  width: '100%',
  objectFit: 'contain',
  backgroundColor: '$pureWhite',
  p: 2,
  opacity: 1,
  aspectRatio: '16/10',
  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.05)',
  },
});

const ProductHeading = styled('h5', {
  fontFamily: '$fontSecondary400',
  textTransform: 'capitalize',
  fs: 7,
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0px',
  height: '38px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  mt: 2,
  mb: 4,
  textDecoration: 'underline solid transparent',
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  textUnderlineOffset: '2px',
  transitionTimingFunction: 'ease-in-out',
  '@mediaMinLarge': {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    height: '48.7px',
  },
  variants: {
    isCardHovered: {
      true: {
        textDecoration: 'underline solid',
      },
    },
  },
});

export default StepTwo;
