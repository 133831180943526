import StepOne from './Steps/StepOne';
import {
  ExplodedViewProvider,
  useExplodedViewData,
} from './ExplodedViewProvider';
import Fetcher from '../../Shared/Common/Fetcher';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import { useEffect, useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

let abortController: AbortController = new AbortController();

export function ExplodedAPI<T>(url: string): Promise<T | undefined> {
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<T, T>(url, signal, (res, resolve) => {
    resolve(res);
  });
}

function Steps() {
  const { step } = useExplodedViewData();

  switch (step) {
    case 1:
      return <StepOne />;
    case 2:
      return <StepTwo />;
    case 3:
      return <StepThree />;
    default:
      return <></>;
  }
}

function ExplodedViewsPage() {
  const [key, setKey] = useState<number>(0);

  const data = useCurrentPage();

  useEffect(() => {
    setKey((key) => key + 1);
  }, [data]);

  return (
    <ExplodedViewProvider key={key}>
      <Steps />
    </ExplodedViewProvider>
  );
}

export default ExplodedViewsPage;
